<template>
  <section class="shadow-xl rounded-xl pt-4 px-5">
    <span class="flex gap-3 items-center mb-2 font-bold text-md"><a-icon type="search"></a-icon> Filtros de
      búsqueda</span>
    <div class="grid grid-cols-4 gap-6 items-center">
      <a-button type="primary" icon="sliders" shape="round" v-if="date !== ''" @click="showFilters = true">Filtros de
        búsqueda</a-button>
      <BaseDate id="date-input" label="Seleccione fecha" type="text" placeholder="DD/MM/AAAA" class="mb-3" v-model="date"
        :disabled-date="disabledDate" @input="handleChangeDate"></BaseDate>

      <div class="h-full w-full mt-0 text-left flex flex-col pt-1">
        <label :for="id" class="mb-2 text-xs md:text-xs text-gray-500 text-opacity-80"><b>Mostrar columnas</b></label>
        <a-select mode="multiple" style="width: 100%" :options="format_columns" @blur="(e, val) => onBlurSelect(e, val)" 
          placeholder="Seleccione las columnas" @mouseleave="handleChange" :filterOption="true" :maxTagCount="1" :allowClear="true"
          :maxTagTextLength="15" v-model="data_select_columns">
        </a-select>
      </div>
    </div>
    <Modal size="5xl" v-show="showFilters">
      <div class="text-left font-bold text-xl relative mt-0">
        <h1 class="text-blue-dark">Filtros de Búsqueda</h1>
        <a-button class="border-none absolute top-0 right-0 bottom-7" size="large" icon="close"
          @click="showFilters = !showFilters"></a-button>
      </div>
      <div class="filters w-full text-left text-md">
        <ul class="main-menu main overflow-auto overscroll-x-none" v-if="!loadingFormats">
          <li class="menu-item hover:bg-gray-200 focus:bg-blue-100 hover:border-xs rounded-md p-1 flex justify-between"
            :class="format === formatActive
                ? 'bg-blue-700 text-white  hover:bg-blue-800 rounded-md pl-4 font-bold'
                : ''
              " v-for="format in formats" :key="format.id" @click="active(format)">
            <span class="">{{ format.format | capitalize }} </span>
            <a-icon type="caret-right" class="mt-0.5"></a-icon>
          </li>
        </ul>
        <div v-else class="flex justify-center relative">
          <a-spin class="relative top-28">
            <a-icon slot="indicator" type="loading" style="font-size: 40px" spin />
          </a-spin>
        </div>
        <ul class="center-menu overflow-auto text-left overscroll-x-none">
          <template v-for="field in formatActive.fields" v-if="formatActive">
            <li @click="optionActive(field)" :class="field === optionActual
                ? 'bg-blue-700 text-white  hover:bg-blue-800 rounded-md pl-4 font-bold'
                : ''
              "
              class="menu-item hover:bg-gray-200 focus:bg-blue-100 border-xl flex justify-between rounded-md p-1 my-1">
              <span class=""> {{ field.description | capitalize }}</span>
              <a-icon type="caret-right" class="mt-0.5"></a-icon>
            </li>
          </template>
          <div v-if="!formatActive" class="flex justify-center relative">
            <a-spin class="relative top-28">
              <a-icon slot="indicator" type="loading" style="font-size: 40px" spin />
            </a-spin>
          </div>
        </ul>
        <ul class="right overflow-auto" @click="totalFiltersSelected" @change="totalFiltersSelected">
          <template>
            <div class="filter-inputs" v-for="filter in formatActive.fields" :key="filter.name">
              <BaseInput v-if="filter.type === 'TEXT'" :maxlength="50" type="text" :label="filter.description"
                v-model="filterSet[filter.name].data" :class="filter.name === optionActual.name ? '' : 'hidden'" />
              <BaseInput v-if="filter.type === 'NUMBER'" :label="filter.description" numeric
                :maxlength="filter.name == 'ingresos' ? 1 : 3" v-model="filterSet[filter.name].data"
                :class="filter.name === optionActual.name ? '' : 'hidden'" />
              <Calendar v-if="filter.type === 'DATETIME'" @selected-date="dateSelected($event, filter.name)"
                :class="filter.name === optionActual.name ? '' : 'hidden'" />
              <div v-if="filter.type === 'RANGE'" :class="filter.name === optionActual.name ? '' : 'hidden'">
                <BaseInput label="Mínimo" v-model="filterSet[filter.name].data[0]" type="number"
                  :maxlength="getFieldLen(filter.name)" />
                <BaseInput label="Máximo" v-model="filterSet[filter.name].data[1]" type="number"
                  :maxlength="getFieldLen(filter.name)" />
              </div>

              <div v-if="filter.type === 'SELECT'">
                <BaseMultiSelect :valuesSelected="filterSet[filter.name].data" :data="filter.data" filter
                  :label="filter.name" @notify-select="setMultiSelect($event, filter.name)"
                  :class="filter.name === optionActual.name ? '' : 'hidden'" />
              </div>
            </div>
            <div v-if="loadingOptions" class="flex justify-center relative">
              <a-spin class="relative top-24">
                <a-icon slot="indicator" type="loading" style="font-size: 40px" spin />
              </a-spin>
            </div>
          </template>
        </ul>
      </div>
      <a-collapse :activeKey="showFilterApplied" :bordered="false" class="bg-white border-hidden relative"
        v-if="selectedKeys > 0">
        <a-collapse-panel key="open" :header="`Filtros aplicados  ${selectedKeys}`">
          <div v-for="(filter, idx) in filterSet" :key="idx" class="overflow-auto max-h-36 relative">
            <template v-if="filter.data">
              <div v-if="filter.type === 'SELECT'">
                <a-tag closable @close="deleteFilter(idx, j)" class="my-1" color="blue" v-for="(tag, j) in filter.data"
                  :key="j">{{ tag | capitalize }}</a-tag>
              </div>
              <div v-else-if="filter.type === 'RANGE' && filter.data.length > 0">
                <a-tag color="blue" class="my-1" closable @close="deleteFilter(idx, -2)">
                  {{
                    (filter.description +
                      `: MIN: ${filter.data[0]} MAX: ${filter.data[1]}`)
                    | capitalize
                  }}
                </a-tag>
              </div>
              <div v-else-if="filter.type !== 'RANGE' && filter.data.length > 0">
                <a-tag color="blue" class="my-1" closable @close="deleteFilter(idx, -1)">
                  {{ filter.description + `: ${filter.data}` }}
                </a-tag>
              </div>
            </template>
          </div>
        </a-collapse-panel>
      </a-collapse>
      <div class="flex mt-3 items-center gap-4">
        <a-button type="primary" ghost icon="delete" shape="round" @click="clearFilters()">Limpiar filtros</a-button>
      </div>
      <div class="flex justify-end mt-3 items-center gap-4">
        <a-button type="danger" ghost shape="round" @click="showFilters = false">CANCELAR</a-button>
        <a-button type="primary" shape="round" :disabled="formatActive.id?false:true" @click="openConfirmation()">GENERAR REPORTE</a-button>
      </div>
    </Modal>
    <Modal id="confirmationModal" v-if="showModalConfirmation">
      <h1 class="text-blue-800 text-3xl font-bold my-3">
        Generar reporte de tarifas
      </h1>
      <p class="font-bold text-lg my-8">
        ¿Está seguro de que ha aplicado todos los filtros necesarios para
        generar el reporte?
      </p>

      <div class="my-5 grid w-10/12 px-2 mx-auto grid-cols-2 align-items-start content-center gap-2">
        <a-checkbox class="text-gray-500 font-bold text-xs ml-3" :checked="defaultCols.nombre_entidad"
          @change="onDefaultCols($event, 'nombre_entidad')">
          Incluir nombre de la entidad
        </a-checkbox>

        <a-checkbox class="text-gray-500 font-bold text-xs" :checked="defaultCols.fecha_reporte"
          @change="onDefaultCols($event, 'fecha_reporte')">
          Incluir fecha
        </a-checkbox>
        <a-checkbox class="text-gray-500 font-bold text-xs m-0" :checked="defaultCols.codigo_entidad"
          @change="onDefaultCols($event, 'codigo_entidad')">
          Incluir código de la entidad
        </a-checkbox>
        <a-checkbox class="text-gray-500 font-bold text-xs ml-6 pl-3" :checked="defaultCols.formato"
          @change="onDefaultCols($event, 'formato')">
          Incluir formato
        </a-checkbox>
      </div>
      <div class="w-full flex flex-col">
        <div class="flex gap-3 justify-center my-3x">
          <BaseButton label="CANCELAR" danger="true" @click.native="closeModal()" />
          <BaseButton label="GENERAR" @click.native="generateReport()" />
        </div>
      </div>
    </Modal>
  </section>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      date: moment().format('YYYY-MM-DD'),
      visibleDropdown: true,
      format_columns: [],
      last_data_select_columns:[],
      data_select_columns: [],
      showFilters: false,
      formatActive: {},
      optionActual: {},
      formats: [],
      groupFilters: [],
      filtersApplied: false,
      loadingReport: false,
      selectedKeys: 0,
      formatDataSelectedLoad: [],
      formatDataSelected: [],
      loadingOptions: false,
      loadingFormats: false,
      filterSet: null,
      showModalConfirmation: false,
      defaultCols: {
        nombre_entidad: true,
        fecha_reporte: true,
        codigo_entidad: true,
        formato: true,
        codigo_registro: true,
        codigo_registro_uc: true,
      },
      showFilterApplied: "",
      count: 0,
    };
  },
  created() {
    this.getFilters();
  },
  methods: {
    arraysAreEqualIgnoreOrder(array1, array2) {
      let sortedArray1 = [...array1].sort();
      let sortedArray2 = [...array2].sort();
      return JSON.stringify(sortedArray1) === JSON.stringify(sortedArray2);
    },
    onBlurSelect(value){
      const areEquals = this.arraysAreEqualIgnoreOrder(this.data_select_columns, this.last_data_select_columns);
      console.log('areEquals:', areEquals)
      if (!areEquals){
        this.generateReport();
      }
    },
    handleChangeDate(value, args) {
      let keys_count = Object.keys(this.formatActive);
      console.log('keys_count:', keys_count, keys_count.length)
      const isFormatSelected = keys_count.length == 0? false: true;
      console.log('isFormatSelected:', isFormatSelected)
      if (isFormatSelected){
        console.log('isFormatSelected:', isFormatSelected)
        this.generateReport();
      }
    },
    handleChange(value, args) {
      const areEquals = this.arraysAreEqualIgnoreOrder(this.data_select_columns, this.last_data_select_columns);
      console.log('areEquals:', areEquals)
      if (!areEquals){
        this.generateReport();
      }
    },
    async getFilters() {
      this.loadingFormats = true;
      let { error, data } = await this.$api.getFilters();
      if (data) {
        this.formats = data;
        this.loadingFormats = false;
      } else if (error) {
        this.loadingFormats = false;
        this.showToast(
          "error",
          "Ocurrió un error obteniendo los formatos. Intente refrescar la página"
        );
      }
    },
    applyFilters() {
      let resultFilter = {};

      this.showModal = false;

      // Obtenemos todas las columnas y las convertimos en formato para el backend

      const nameColumns = Object.keys(this.filterSet);

      nameColumns.forEach((item) => {
        resultFilter = {
          ...resultFilter,
          ...this.convertTypeFilter(
            item,
            this.filterSet[item].type,
            this.filterSet[item].data
          ),
        };
      });
      // Se agrega el rango de fechas para la consulta
      resultFilter["fecha_reporte"] = {
        filter_type: "date",
        date_from: this.date,
        type: "lessThanOrEqual",
      };
      
      return resultFilter;
    },
    onDefaultCols(event, col) {
      this.defaultCols[col] = event.target.checked;
    },
    updateColumnsFilter(){
      const excludedNames = Object.keys(this.applyFilters());
      const form_c = (this.formatActive.fields || [])
        .map(item => ({
          label: this.capitalizedText(item.description),
          value: item.name
        }))
        .filter(item => !excludedNames.includes(item.value));
      this.data_select_columns = [];
      this.data_select_columns = form_c.map(item => item.value);
      this.format_columns = form_c;
    },
    generateReport() {
      this.last_data_select_columns = this.data_select_columns;
      const filter_model = this.applyFilters()
      this.$emit("notify-filters", {
        filter_model: filter_model,
        filterSet: this.filterSet,
        format: this.formatActive,
        defaultCols: this.defaultCols,
        append_columns: this.data_select_columns
      });
      this.showFilters = false;
      this.showModalConfirmation = false;
      this.filtersApplied = true;
    },
    convertTypeFilter(name_att, filter_type, data) {
      let resJSON = {};
      // Convertir un campo en su valor conocido por el backend
      switch (filter_type) {
        case "SELECT":
          if (data?.length > 0)
            resJSON[name_att] = {
              filter_type: "set",
              values: data,
            };

          break;
        case "TEXT":
          if (data !== null && data !== "") {
            resJSON[name_att] = {
              filter_type: "text",
              type: "contains",
              filter: data,
            };
          }
          break;

        case "NUMBER":
          if (data !== null && data !== "") {
            resJSON[name_att] = {
              filter_type: "number",
              type: "equals",
              filter: data,
              filterTo: null,
            };
          }
          break;
        case "DATETIME":
          if (data !== null && data !== "") {
            resJSON[name_att] = {
              filter_type: "date",
              type: "greaterThanOrEqual",
              date_from: data,
              date_to: null,
            };
          }
          break;
        case "RANGE":
          if (data[0] && data[0] !== "" && data[1] && data[1] !== "") {
            resJSON[name_att] = {
              filter_type: "number",
              operator: "AND",
              condition1: {
                filter_type: "number",
                filter: data[0],
                type: "greaterThan",
              },
              condition2: {
                filter_type: "number",
                filter: data[1],
                type: "lessThanOrEqual",
              },
            };
          }
          break;
        default:
          break;
      }

      return Object.keys(resJSON).length > 0 ? resJSON : null;
    },
    setFormatData(formatData) {
      this.formatDataSelected = formatData;
    },
    setMultiSelect(data, filter) {
      this.filterSet[filter].data = data;
    },
    optionActive(item) {
      this.optionActual = item;
    },
    active(item) {
      this.optionActual = {};
      this.formatActive = item;
    },
    clearFilters() {
      this.formatActive = {};
      this.optionActual = {};
      this.selectedKeys = 0;
    },
    async loadDataForFilter() {
      if (this.optionActual.type === "SELECT" && !this.optionActual?.data) {
        let { data, error } = await this.$api.getDataForFilterFields({
          format_id: this.formatActive.id.toString(),
          slug_name: this.optionActual.name.toString(),
        });
        if (data) {
          const idx = this.formatActive.fields.findIndex(
            (filter) => filter.name === this.optionActual.name
          );
          this.formatActive.fields[idx].data = data;
          this.loadingOptions = false;
        } else if (error) {
          this.showToast(
            "error",
            "Ocurrió un error cargando los datos. Intente nuevamente o actualice la página."
          );
          this.loadingOptions = false;
        }
      } else {
        this.loadingOptions = false;
      }
    },
    dateSelected(date, filter) {
      this.filterSet[filter] = date;
    },
    deleteFilter(filterIdx, dataIdx) {
      if (dataIdx === -1) this.filterSet[filterIdx].data = null;
      else if (dataIdx === -2) this.filterSet[filterIdx].data = [];
      else this.filterSet[filterIdx].data.splice(dataIdx, 1);
      this.selectedKeys = 0;
      setTimeout(() => {
        this.totalFiltersSelected();
        this.showFilterApplied = "open";
      }, 7);
    },
    totalFiltersSelected() {
      this.selectedKeys = 0;
      this.updateColumnsFilter()
      const filters = Object.values(this.filterSet);
      for (let index = 0; index < filters.length; index++) {
        const filter = filters[index].data;

        if (filter) {
          if (Array.isArray(filter)) {
            let length = filter.length;
            this.selectedKeys += length;
          } else {
            this.selectedKeys += 1;
          }
        }
      }
    },
    showToast(result = "info", title = "Resultado", msg, btn) {
      const key = `open${Date.now()}`;
      this.$notification[result]({
        message: title,
        description: msg,
        btn: btn
          ? (h) => {
            return h(
              "a-button",
              {
                props: {
                  type: "primary",
                  size: "small",
                },
                on: {
                  click: () => this.$notification.close(key),
                },
              },
              "Entendido"
            );
          }
          : null,
        key,
        onClose: close,
        style: {
          width: "500px",
          marginLeft: `${350 - 500}px`,
        },
      });
    },
    loadFilterSet() {
      this.filterSet = {};
      if (this.formatActive?.fields?.length > 0)
        this.formatActive.fields.forEach((el) => {
          this.filterSet[el.name] = {
            type: el.type,
            description: el.description,
            name: el.name,
            data: el.type == "SELECT" || el.type == "RANGE" ? [] : null,
          };
        });
    },
    openConfirmation() {
      this.showFilters = false;
      this.showModalConfirmation = true;
    },
    closeModal() {
      this.showModalConfirmation = false;
      this.showFilters = true;
    },
    getFieldLen(field) {
      const lengths = {
        costo: 10,
        tasa: 7,
        num_clientes_unicos: 8,
        cuota_manejo: 7,
        num_trans_in_cuota: 2,
        costo_fijo: 6,
        costo_proporcional: 6,
      };

      return lengths[field];
    },
    disabledDate(current) {
      return current && current > moment().endOf('day');
    },
    capitalizedText(myText) {
      if (!myText) return '';
      return myText.charAt(0).toUpperCase() + myText.slice(1).toLowerCase();
    }
  },
  computed: {
  },
  watch: {
    optionActual: function (newVal, oldVal) {
      this.loadingOptions = true;
      this.loadDataForFilter();
    },
    formatActive: function (newVal, oldVal) {
      if (newVal?.id !== oldVal?.id) this.loadFilterSet();
    },
    filterSet:function (newVal, oldVal) {
      this.updateColumnsFilter();
    }
  },
};
</script>
<style scoped>
.filters {
  margin-top: 2em;
  height: 20em;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas: "main center left";
  grid-gap: 0.1em;
  border-top: solid 1px rgba(0, 0, 0, 0.164);
  border-bottom: solid 1px rgba(0, 0, 0, 0.164);
}

.center-menu {
  padding: 1em;
  grid-area: center;
  border-left: solid 1px rgba(0, 0, 0, 0.164);
  border-right: solid 1px rgba(0, 0, 0, 0.164);
}

.right {
  padding: 1em 0.2em;
  grid-area: left;
}

.main-menu {
  padding: 1em;
  position: relative;
  grid-area: main;
  width: 100%;
}

.sub-menu {
  position: absolute;
}

.menu-item {}

.calendar {
  width: 100%;
  height: 100%;
}
</style>

<style>
.right .select .checkboxLayer {
  position: absolute;
  display: block;
  top: -3em;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  border: none;
  box-shadow: none;
}

.right .select .checkBoxContainer {
  height: 13em;
}

.right .select>button {
  display: none;
}

.ant-tag {
  white-space: normal;
}
</style>
